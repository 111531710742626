import React from "react";
import ReactDOM from "react-dom";

import App from "./App";
// import Apxor from "apxor";
// import CE from "apxor-qe";
// import ApxorRTM from "apxor-rtm";
import { BrowserRouter } from "react-router-dom";

let retryCount = 0;
const maxRetries = 5;
function loadApxorScripts() {
	console.log("Apxor: Loading scripts conditionally");
	const e = document.createElement("script");
	(e.src = "https://unpkg.com/apxor@latest"),
		(e.type = "text/javascript"),
		(e.defer = !0);
	const t = document.createElement("script");
	(t.src = "https://unpkg.com/apxor-qe@latest"), (t.type = "text/javascript");
	const o = document.createElement("script");
	(o.src = "https://unpkg.com/apxor-rtm@latest"),
		(o.type = "text/javascript"),
		document.head.appendChild(e),
		(e.onload = () => {
			document.head.appendChild(t);
		}),
		(t.onload = () => {
			document.head.appendChild(o);
		}),
		(o.onload = () => {
			initApxorSDK();
		});
}
function initApxorSDK() {
	console.log("init apxor sdk");
	// console.log("Apxor: Initializing with key", window.APP_CONSTANTS.APXOR_KEY),
	window.ApxorCallbackInterface = {
		validate_campaign_creation: function (e) {
			if (!e || !e.campaign_name) return !1;
			const t = e.campaign_name,
				o = document.querySelectorAll("div[col-id='campaignName']");
			for (var n = 0; n < o.length; n++) if (o[n].textContent === t) return !0;
			return !1;
		},
	};
	const t = new Date();
	(e = window),
		document,
		(Apxor = e.Apxor || {
			_q: [],
			_st: t,
		}),
		e?.Apxor ||
			[
				"init",
				"setUserId",
				"setUserProperties",
				"setSessionProperties",
				"logPageView",
				"logEvent",
				"logClientEvent",
				"setAppVersion",
				"getClientId",
				"getSessionId",
				"startNewSession",
				"endSession",
				"flattenJSON",
				"setRedirectionHandler",
			].forEach(function (e) {
				Apxor[e] = function () {
					this._q.push({
						m: e,
						args: arguments,
					});
				};
			}),
		console.log("before apxor initialized"),
		Apxor.init(
			"cfd1eb74-b691-4971-96a3-113d77df9dc5",
			{
				idle_time_out: 1800,
				plugins: ["ApxorRTM"],
			},
			function (e) {
				console.log("APXOR SDK Initialized successfully");
			},
			function () {
				console.log("APXOR SDK initialization failed");
			}
		),
		// console.log("location is", window.APP_CONSTANTS.LOCATION),
		// Apxor.setUserProperties({
		// 	location: window.APP_CONSTANTS.LOCATION,
		// }),
		// Apxor.setUserId(localStorage.getItem("userName"));

		retryCount < 5
			? (retryCount++,
			  console.log(
					"APP_CONSTANTS.APXOR_KEY not available. retry count is ",
					retryCount
			  ),
			  setTimeout(initApxorSDK, 1e3))
			: console.log("APP_CONSTANTS.APXOR_KEY not available. Stopping retries.");
	var e;
}

loadApxorScripts();
// Apxor.init(
//   "cfd1eb74-b691-4971-96a3-113d77df9dc5",
//   {
//     plugins: ["ApxorRTM"],
//     deps: [ApxorRTM, CE],
//     version: "0.1",
//     debug: true,
//     level: "debug",
//   },
//   (e) => console.log("Successfully Initialized Apxor SDK:", e),
//   (e) => console.log("Apxor SDK Initialization failed:", e)
// );
//Initializing Apxor

ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById("root")
);
