import { useState } from "react";
import { useNavigate } from "react-router-dom";

const LoginForm = () => {
	const [username, setUsername] = useState("");
	const [password, setPassword] = useState("");
	const [error, setError] = useState("");
	const [success, setSuccess] = useState(false);
	const navigate = useNavigate();

	const handleSubmit = (e) => {
		e.preventDefault();
		setError("");
		setSuccess(false);

		if (username === "user" && password === "password") {
			setSuccess(true);
			// In a real app, you would handle the successful login here
			// For example, redirect to another page or update app state
		} else {
			setError("Invalid username or password");
		}
	};

	return (
		<div className='login-container'>
			<form onSubmit={handleSubmit} className='login-form'>
				<h2>Login</h2>
				<div className='form-group'>
					<label htmlFor='username'>Username:</label>
					<input
						type='text'
						id='username'
						value={username}
						onChange={(e) => setUsername(e.target.value)}
						required
					/>
				</div>
				<div className='form-group'>
					<label htmlFor='password'>Password:</label>
					<input
						type='password'
						id='password'
						value={password}
						onChange={(e) => setPassword(e.target.value)}
						required
					/>
				</div>
				{error && <div className='error-message'>{error}</div>}
				{success && <div className='success-message'>Login successful!</div>}
				<button
					type='submit'
					className='login-button'
					onClick={() => {
						Apxor.setUserId("swathi");
						Apxor.logEvent("new");
						navigate("/languages");
					}}
				>
					Log in
				</button>
			</form>
			<p className='login-info'>
				Use username: 'user' and password: 'password' to log in.
			</p>
			<style jsx>{`
				.login-container {
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					height: 100vh;
					font-family: Arial, sans-serif;
				}
				.login-form {
					background-color: #f0f0f0;
					padding: 20px;
					border-radius: 5px;
					box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
					width: 300px;
				}
				.form-group {
					margin-bottom: 15px;
				}
				.form-group label {
					display: block;
					margin-bottom: 5px;
				}
				.form-group input {
					width: 100%;
					padding: 8px;
					border: 1px solid #ddd;
					border-radius: 4px;
				}
				.login-button {
					width: 100%;
					padding: 10px;
					background-color: #007bff;
					color: white;
					border: none;
					border-radius: 4px;
					cursor: pointer;
				}
				.login-button:hover {
					background-color: #0056b3;
				}
				.error-message {
					color: red;
					margin-bottom: 10px;
				}
				.success-message {
					color: green;
					margin-bottom: 10px;
				}
				.login-info {
					margin-top: 20px;
					font-size: 0.9em;
					color: #666;
				}
			`}</style>
		</div>
	);
};

export default LoginForm;
